'use client';
import React from 'react';
// import { motion } from 'framer-motion'; // Import motion from framer-motion
import style from './HeroSection.module.scss';
import { cn } from '@/lib/utils';
import { Icons } from '@/components/icons';
import { Metrix } from '@/components/client/Metrix/Metrix';
import teamImage from '@/public/images/home/team.webp';
import carImage from '@/public/images/home/car.webp';
import gradientImage from '@/public/images/home/gradient.webp';
import Image from 'next/image';
import { CompanyLabel } from '@/components/client/CompanyLabel/CompanyLabel';
import Button from '@/components/ui/Button/Button';
import { useModalContext } from '@/context/ModalsContext';

const metrixItems = [
  { title: 'Total Customer', count: '1500', mark: '+' },
  { title: 'Client Satisfaction', count: '100', mark: '%' },
  { title: 'Team Members', count: '50', mark: '+' },
];

// const heroVariants = {
//   hidden: { opacity: 0, y: -20 },
//   visible: { opacity: 1, y: 0 },
// };

export const HeroSection = () => {
  const { setIsModalOpened, setisModalQuoteOpen } = useModalContext();
  return (
    <div
      className={cn(
        style.wrapper,
        'flex w-full flex-col justify-between md:flex-row'
      )}
      /*  initial="hidden"
      animate="visible"
      variants={heroVariants}
      transition={{ duration: 0.6 }} // Adjust duration as needed */
    >
      <div className="w-full">
        <div
          className="mb-6"
          /*  variants={heroVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.6, delay: 0.2 }} // Stagger animations */
        >
          <h1 className="font-poppins text-left text-[48px] font-semibold leading-[1.2] md:text-[76px]">
            All Things Home
            <span className="font-poppins flex flex-wrap gap-4 text-left text-[48px] font-semibold leading-[1.2] md:gap-20 md:text-[76px]">
              <span>with</span>
              <span className="h-[80px] w-full md:w-auto">
                <CompanyLabel />
              </span>
            </span>
          </h1>
        </div>

        <p
          className="text-customGray mb-12 max-w-[532px] text-left text-[16px] font-normal leading-[28px] md:text-[18px]"
          /*  variants={heroVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.6, delay: 0.4 }} // Stagger animations */
        >
          Our expert services cover everything you need to turn your vision into
          reality and improve your home living experience, including HVAC,
          electrical, plumbing, construction, and real estate.
        </p>

        <div className="mb-[60px] text-left">
          <Button onClick={() => setIsModalOpened(true)} variant="main">
            <p className="flex w-full items-center justify-between gap-10 md:w-[412px]">
              <span>Book a Service</span>{' '}
              <span>
                <Icons.callToAction />
              </span>
            </p>
          </Button>
        </div>
        <Metrix items={metrixItems} />
      </div>

      <div className="xl-custom:block relative hidden w-full">
        <div className="relative">
          <div className="relative z-40 flex justify-end">
            <button
              className="mb-16 flex w-fit cursor-pointer items-center gap-6 rounded-full bg-black px-5 py-2 text-start text-lg text-white"
              /*  variants={heroVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.6, delay: 0.6 }} */
              onClick={(e) => {
                e.stopPropagation();
                setisModalQuoteOpen(true);
              }}
            >
              <span>
                <Icons.homeSetting />
              </span>{' '}
              <span>Get a Free Quote</span>
            </button>
          </div>
          <div className={cn(style.carWrapper, 'absolute z-20')}>
            <Image src={carImage} alt="car" priority />
          </div>
          <div className={cn(style.gradientWrapperBg, 'absolute z-10')}>
            <Image src={gradientImage} alt="car" priority />
          </div>
        </div>
        <div className="absolute bottom-16 z-30">
          <Image src={teamImage} alt="team" priority />
        </div>
      </div>
    </div>
  );
};
