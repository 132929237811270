import React from 'react';
import style from './CompanyLabel.module.scss';
import { cn } from '@/lib/utils';

export const CompanyLabel = () => {
  return (
    <span className={style.bgTextWrapper}>
      <span
        className={cn(
          style.text,
          'text-start text-7xl font-semibold text-white '
        )}
      >
        Tytum
      </span>
      <span className={style.bgTextWrapperInner}></span>
      <span className={style.bgTextWrapperInnerBlack}></span>
    </span>
  );
};
