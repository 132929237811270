import React, { FC } from 'react';
import style from './ServiceCard.module.scss';
import { cn } from '@/lib/utils';
import Link from 'next/link';

interface IProps {
  title: string;
  description: string;
  icon: string;
  isBlackBg: boolean;
  path: string;
}

export const ServiceCard: FC<IProps> = ({
  title,
  icon,
  description,
  isBlackBg,
  path,
}) => {
  const Icon = icon;
  return (
    <div className={cn(style.wrapper, isBlackBg ? 'bg-black' : 'bg-white')}>
      <div
        style={{ backgroundColor: isBlackBg ? '#FFFFFF1A' : '#1212121a' }}
        className={cn(style.iconWrapper)}
      >
        {<Icon />}
      </div>
      <p className={cn(isBlackBg ? 'text-white' : 'text-black')}>{title}</p>
      <p className={cn(isBlackBg ? 'text-white' : 'text-black')}>
        {description}
      </p>
      <Link
        href={path}
        className={cn(
          isBlackBg ? 'bg-black' : 'bg-white',
          isBlackBg ? 'border-white' : 'border-black',
          isBlackBg ? 'text-white' : 'text-black',
          ' rounded-full border px-5  py-2  text-base'
        )}
      >
        More details
      </Link>
    </div>
  );
};
