'use client';
import React from 'react';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { Label } from '@/components/client/Label/Label';
import Image from 'next/image';
import licenceImage1 from '@/public/images/home/License-1.webp';
import licenceImage2 from '@/public/images/home/License-2.webp';
import licenceImage3 from '@/public/images/home/License-3.webp';

export const LicenceSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); // Анимация сработает только один раз

  return (
    <div ref={ref}>
      <div className="flex justify-center">
        <Label label="Licenses" />
      </div>
      <h2 className="mb-10 mt-5">
        <motion.p
          initial={{ opacity: 0, y: 50 }} // Начальные параметры анимации
          animate={isInView ? { opacity: 1, y: 0 } : {}} // Анимация при появлении
          transition={{ duration: 0.5 }} // Длительность анимации
          className="text-textOrange text-4xl font-semibold"
        >
          Your. Licensed
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: 50 }} // Начальные параметры анимации
          animate={isInView ? { opacity: 1, y: 0 } : {}} // Анимация при появлении
          transition={{ duration: 0.5 }} // Длительность анимации
          className="text-4xl font-semibold"
        >
          Reliable Contractor
        </motion.p>
      </h2>
      <div className="flex flex-wrap items-center justify-center gap-4 md:flex-nowrap">
        <motion.div
          initial={{ opacity: 0, y: 50 }} // Начальные параметры анимации
          animate={isInView ? { opacity: 1, y: 0 } : {}} // Анимация при появлении
          transition={{ duration: 0.5 }} // Длительность анимации
        >
          <Image src={licenceImage2} alt="licence-2" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }} // Начальные параметры анимации
          animate={isInView ? { opacity: 1, y: 0 } : {}} // Анимация при появлении
          transition={{ duration: 0.5 }} // Длительность анимации
        >
          <Image src={licenceImage1} alt="licence-1" />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }} // Начальные параметры анимации
          animate={isInView ? { opacity: 1, y: 0 } : {}} // Анимация при появлении
          transition={{ duration: 0.5 }} // Длительность анимации
        >
          <Image src={licenceImage3} alt="licence-3" />
        </motion.div>
      </div>
    </div>
  );
};
