'use client';
import React, { useRef, useCallback } from 'react';
import style from './TestimonalsSection.module.scss';
import { Label } from '@/components/client/Label/Label';
import { Icons } from '@/components/icons';
import { Button } from '@/components/ui/button';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TestimonalsCard } from './components/TestimonalsCard/TestimonalsCard';
import 'swiper/css';

import image1 from '@/public/images/testimonals/image-1.webp';
import image2 from '@/public/images/testimonals/image-2.webp';
import image3 from '@/public/images/testimonals/image-3.webp';
import image4 from '@/public/images/testimonals/image-4.webp';
import image5 from '@/public/images/testimonals/image-5.webp';
import image6 from '@/public/images/testimonals/image-6.webp';
import image7 from '@/public/images/testimonals/image-7.webp';
import image8 from '@/public/images/testimonals/image-8.webp';
import image9 from '@/public/images/testimonals/image-9.webp';
import image10 from '@/public/images/testimonals/image-10.webp';
import image11 from '@/public/images/testimonals/image-11.webp';
import image12 from '@/public/images/testimonals/image-12.webp';

import Image from 'next/image';

import googlePick from '@/public/images/google.png';

const data = [
  {
    items: [
      {
        type: 'yelp',
        image: image1,
      },
    ],
  },
  {
    items: [
      {
        type: 'google',
        image: image2,
      },
      {
        type: 'yelp',
        image: image3,
      },
    ],
  },
  {
    items: [
      {
        type: 'yelp',
        image: image4,
      },
      {
        type: 'google',
        image: image5,
      },
    ],
  },
  {
    items: [
      {
        type: 'google',
        image: image6,
      },
      {
        type: 'yelp',
        image: image7,
      },
    ],
  },
  {
    items: [
      {
        type: 'yelp',
        image: image8,
      },
      {
        type: 'google',
        image: image9,
      },
    ],
  },
  {
    items: [
      {
        type: 'google',
        image: image10,
      },
      {
        type: 'yelp',
        image: image11,
      },
    ],
  },
  {
    items: [
      {
        type: 'yelp',
        image: image12,
      },
    ],
  },
];

const SwiperItem = ({ items }) => {
  return (
    <div className={style.card}>
      {items.map((item, idx) => (
        <TestimonalsCard key={item.type || idx} {...item} />
      ))}
    </div>
  );
};

export const TestimonalsSection = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <div className={'w-full'}>
      {' '}
      <div>
        <div className="mb-10 flex items-center justify-start">
          <Label label="Testimonals" />
        </div>
        <div className="mb-5 flex w-full items-center justify-between ">
          <h2 className="mb-8 w-full text-4xl font-bold">
            <p className="text-start">
              <span className="textBlack ">Customer Reviews</span>{' '}
            </p>
            <p className="mb-10 text-start">
              <span className="textBlack text-xl font-normal leading-7">
                Here&apos;s what some of our valued clients have to say about
                their experience with Tytum.
              </span>
            </p>
            <p className="textGrey flex w-full flex-col items-center justify-between text-start font-normal md:flex-row">
              <div className="mb-4 flex items-center gap-4 md:mb-0 md:gap-10">
                <span className="flex items-center">
                  <Image
                    style={{ marginRight: '-28px' }}
                    src={googlePick}
                    alt="google"
                    quality={100}
                  />{' '}
                  <Icons.googleReviews />
                </span>
                <span>
                  <Icons.yelpReviews />
                </span>
              </div>
              <div className={style.controls}>
                <Button
                  aria-label="previous"
                  onClick={handlePrev}
                  className="mb-2 rounded-full border border-black bg-white px-5 py-2 text-lg text-black md:mb-0"
                >
                  <Icons.arrowLeft />
                </Button>

                <Button
                  aria-label="next"
                  onClick={handleNext}
                  className="hover:bg-black-200 rounded-full bg-black px-5 py-2 text-white"
                >
                  <Icons.arrowRight />
                </Button>
              </div>
            </p>
          </h2>
        </div>
      </div>
      <Swiper
        ref={sliderRef}
        slidesPerView={1}
        spaceBetween={24}
        navigation={true}
        effect="fade"
        loop={true}
        // autoplay={true}
        modules={[Autoplay, Navigation]}
        className="testimonals-swiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
      >
        {data.map((item, idx) => (
          <SwiperSlide key={idx}>
            <SwiperItem items={item.items} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
