import React, { FC } from 'react';
import style from './SmallBlogCard.module.scss';
import Image from 'next/image';
import { Icons } from '@/components/icons';

interface IProps {
  image: string;
  title: string;
  date: string;
  time: string;
}

export const SmallBlogCard = ({ item }: { item: IProps }) => {
  return (
    <article className={style.article}>
      <div className={style.imgWrapper}>
        <Image src={item.image} alt={item.title} />
      </div>
      <div className={style.contentWrapper}>
        <p className={style.heading}>
          <span>{item.date}</span>
          <span>
            <Icons.dotIcon />
          </span>
          <span>{item.time}</span>
        </p>
        <div
          style={{
            marginBottom: '20px',
            border: '1px solid #4F4F4F',
            opacity: '20%',
          }}
        ></div>
        <p className="text-xl text-black font-semibold text-left mb-5">
          {item.title}
        </p>
      </div>
    </article>
  );
};
