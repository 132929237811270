import React, { FC } from 'react';
import { cn } from '@/lib/utils';
import style from './Metrix.module.scss';
interface IItem {
  count: string;
  mark: string;
  title: string;
}

interface IProps {
  items: IItem[];
}

const MetrixItem = ({ item }: { item: IItem }) => {
  return (
    <div className="flex flex-col">
      <div className="counter flex justify-center items-center mb-1">
        <span className="text-[36px] font-semibold text-black">
          {item.count}
        </span>
        <span className={cn(style.textOrange, ' text-[36px] font-semibold')}>
          {' '}
          {item.mark}
        </span>
      </div>
      <p className="counter-name text-[16px] text-customGray">{item.title}</p>
    </div>
  );
};

export const Metrix: FC<IProps> = ({ items }) => {
  return (
    <div className="flex gap-8">
      {items.map((item) => (
        <MetrixItem item={item} key={item.title} />
      ))}
    </div>
  );
};
