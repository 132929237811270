import React from 'react';
import style from './CallUsSection.module.scss';
import phoneImage from '@/public/images/home/phone.webp';
import Image from 'next/image';

export const CallUsSection = () => {
  return (
    <section className={style.container}>
      <div className={style.linkWrapper}>
        <p className="text-white">Call us now:</p>
        <a className="text-white" href="tel:+16193494397">
          (619) 349-4397
        </a>
      </div>
      <div className={style.imageWrapper}>
        <Image style={{ maxWidth: '293px' }} src={phoneImage} alt="phone" />
      </div>
    </section>
  );
};
