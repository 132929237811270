import Image from 'next/image';
import React, { FC } from 'react';
import { Icons } from '@/components/icons';
import style from './TestimonalsCard.module.scss';

import googlePick from '@/public/images/google.png';

interface IProps {
  type: 'google' | 'yelp';
  image: string;
}

export const TestimonalsCard: FC<IProps> = ({ type, image }) => {
  return (
    <div className={style.wrapper}>
      <Image src={image} quality={100} alt="testimonals" />
      {type === 'google' ? (
        <div className="flex">
          <Image
            style={{ marginRight: '-28px' }}
            src={googlePick}
            alt="google"
            quality={100}
          />{' '}
          <Icons.googleReviews />
        </div>
      ) : (
        <Icons.yelpReviews />
      )}
    </div>
  );
};
