'use client';

import React from 'react';
import { Container } from '@/components/client/Container/Container';
import { Section } from '@/components/client/Section/Section';
import { HeroSection } from '@/features/client/HeroSection/HeroSection';
import { AboutUsSection } from '@/features/client/AboutUsSection/AboutUsSection';
import { LicenceSection } from '@/features/client/LicenceSection/LicenceSection';
import { CallUsSection } from '@/features/client/CallUsSection/CallUsSection';
import { AwardsSection } from '@/features/client/AwardsSection/AwardsSection';
import { RebatesSection } from '@/features/client/RebatesSection/RebatesSection';
import { OurBlogSection } from '@/features/client/OurBlogSection/OurBlogSection';
import { TestimonalsSection } from '@/features/client/TestimonalsSection/TestimonalsSection';
import { OurServicesSection } from '@/features/client/OurServicesSection/OurServicesSection';
import { ContactUsBanner } from '@/features/client/ContactUsBanner/ContactUsBanner';
import { ContactUsSection } from '@/features/client/ContactUsSection/ContactUsSection';

const HomePage = () => {
  return (
    <div>
      <Section variant="primary">
        <Container>
          <HeroSection />
        </Container>
      </Section>
      <Section variant="secondary">
        <Container>
          <AboutUsSection />
        </Container>
      </Section>
      <Section variant="secondary">
        <Container>
          <LicenceSection />
        </Container>
      </Section>
      <CallUsSection />
      <Section variant="primary">
        <Container>
          <OurServicesSection />
        </Container>
      </Section>
      <Section variant="primary" styles={{ paddingTop: 0 }}>
        <Container>
          <ContactUsSection />
        </Container>
      </Section>
      <Section variant="secondary">
        <Container>
          <AwardsSection />
        </Container>
      </Section>
      <Section variant="primary">
        <Container>
          <TestimonalsSection />
        </Container>
      </Section>
      <Section variant="secondary">
        <Container>
          <RebatesSection />
        </Container>
      </Section>
      <Section variant="secondary">
        <Container>
          <OurBlogSection />
        </Container>
      </Section>
      <Section variant="secondary">
        <Container>
          <ContactUsBanner />
        </Container>
      </Section>
    </div>
  );
};

export default HomePage;
