'use client';

import React, { useRef } from 'react';
import { RequestFormMain } from '../Forms/RequestFormMain/RequestFormMain';
import { Label } from '@/components/client/Label/Label';
import { motion, useInView } from 'framer-motion';
export const ContactUsSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <div style={{ width: '100%' }} ref={ref}>
      <div className="flex justify-center">
        <Label label="Contact Us" />
      </div>
      <h2 className="mb-10 mt-5">
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
          className="mb-4 text-4xl font-semibold"
        >
          Drop Us a Line!
        </motion.p>
        <p>
          Looking for a service or need more information? Get in touch with us
          today—we&apos;re here to help!
        </p>
      </h2>
      <RequestFormMain />
    </div>
  );
};
