import React from 'react';
import Image, { StaticImageData } from 'next/image';
import style from './RebatesItem.module.scss';
import { Icons } from '@/components/icons';
import { formatCurrency } from '@/helpers/formatCurrency';

interface IProps {
  image: StaticImageData;
  price: number;
  width: number;
}

export const RebatesItem: React.FC<IProps> = ({ image, price, width }) => {
  return (
    <article className={style.article}>
      <div>
        <Image
          src={image}
          alt="image"
          style={{ maxWidth: `${width}px`, margin: '0 auto' }}
        />
      </div>
      <div className={style.priceBlock}>
        <span>
          <Icons.upToArrow />
        </span>
        <span className={style.title}>Up to</span>
        <span className={style.price}>{formatCurrency(price)}</span>
      </div>
    </article>
  );
};
