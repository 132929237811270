import React from 'react';
import style from './Section.module.scss';
import { cn } from '@/lib/utils';

interface SectionProps {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'accent' | 'darken' | 'white';
  styles?: object;
}

export const Section = ({
  children,
  variant = 'primary',
  styles,
}: SectionProps) => {
  return (
    <section style={styles} className={cn(style.section, style[variant])}>
      {children}
    </section>
  );
};
