import React, { FC } from 'react';
import style from './Label.module.scss';

interface IProps {
  label: string;
}

export const Label: FC<IProps> = ({ label }) => {
  return <div className={style.label}>{label}</div>;
};
