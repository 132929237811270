'use client';
import React, { useRef, useCallback } from 'react';
import style from './OurServicesSection.module.scss';
import { Label } from '@/components/client/Label/Label';
import { Icons } from '@/components/icons';
import { Button } from '@/components/ui/button';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ServiceCard } from './components/ServiceCard/ServiceCard';
import {
  HeatingAndAirServiceIcon,
  BrokerageIcon,
  CounstructionIcon,
  ElectricalProblemIcon,
  PlumbingSolution,
  RemodelingIcon,
} from '@/public/icons/services/icons';
import 'swiper/css';
import Link from 'next/link';

const data = [
  {
    icon: HeatingAndAirServiceIcon,
    title: 'Heating and Air Services',
    description:
      'Whether you need custom HVAC solutions, system repairs, or new installations, our expert team is here to help.',
    path: '/services/heating-and-air',
  },
  {
    icon: ElectricalProblemIcon,
    title: 'Electrical Services',
    path: '/services/electrical',

    description:
      'From simple repairs to complete rewiring projects, our electrical solutions are designed to keep your home safe.',
  },
  {
    icon: PlumbingSolution,
    title: 'Plumbing',
    path: '/services/plumbing',

    description: `Don't let plumbing issues disrupt your daily routine. Our plumbing solutions cover everything from leaky faucets.`,
  },
  {
    icon: RemodelingIcon,
    title: 'Remodeling',
    path: '/services/remodeling',

    description:
      'Elevate your space with our expert remodeling. From kitchens to outdoor areas, we bring your vision to life.',
  },
  {
    icon: CounstructionIcon,
    title: 'New Construction',
    path: '/services/construction',

    description:
      'Build your dream home with our expert new construction services, ensuring quality every step of the way.',
  },
  {
    icon: BrokerageIcon,
    title: 'Real Estate Brokerage',
    path: '/services/brokerage',

    description:
      'Buy or sell with confidence using our expert real estate guidance for the best results.',
  },
];

export const OurServicesSection = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className={'w-full'}>
      {' '}
      <div>
        <div className="mb-5 flex items-center justify-start">
          <Label label="Our Services" />
        </div>
        <div className="mb-10 flex w-full flex-col items-center justify-between md:flex-row">
          <h2 className="mb-8 w-full text-4xl font-bold">
            <p className="text-start">
              <span className="text-textOrange font-semibold">
                The Best Service.
              </span>
            </p>
            <p className="text-start">
              <span className="text-textBlack font-semibold">
                The Best Quality.
              </span>
            </p>
          </h2>

          <div className="mt-4 flex w-full items-center justify-center md:mt-0 md:justify-end">
            <div className={style.controls}>
              <Button
                aria-label="previous"
                onClick={handlePrev}
                className="mr-2 rounded-full border border-black bg-white px-5 py-2 text-lg text-black"
              >
                <Icons.arrowLeft />
              </Button>
              <Button
                aria-label="next"
                onClick={handleNext}
                className="rounded-full bg-black px-5 py-2 text-lg text-white"
              >
                <Icons.arrowRight />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        ref={sliderRef}
        slidesPerView={1}
        spaceBetween={24}
        navigation={true}
        effect="fade"
        loop={true}
        // autoplay={true}
        modules={[Autoplay, Navigation]}
        className="testimonals-swiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
      >
        {data.map((item, idx) => (
          <SwiperSlide key={idx}>
            <ServiceCard isBlackBg={idx % 2 === 0} {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex justify-center pt-20">
        {' '}
        <Link
          href="/company/about"
          className="  mb-16 flex w-fit items-start  gap-2 rounded-full border border-black bg-white px-3 py-2 text-start text-base text-black hover:bg-gray-200"
        >
          <span className="text-base">More About Us</span>{' '}
          <span>{<Icons.info />}</span>
        </Link>
      </div>
    </div>
  );
};
