'use client';
import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import style from './RebatesSection.module.scss';
import techImage from '@/public/images/rebates/tech.webp';
import energyImage from '@/public/images/rebates/energy.webp';
import bruantImage from '@/public/images/rebates/bryant.webp';
import goldenStateImage from '@/public/images/rebates/golden_state.webp';
import { RebatesItem } from './components/RebatesItem/RebatesItem';
import { Label } from '@/components/client/Label/Label';

const items = [
  {
    image: techImage,
    price: 14000,
    width: 300,
  },
  {
    image: energyImage,
    price: 3200,
    width: 180,
  },
  {
    image: bruantImage,
    price: 2200,
    width: 192,
  },
  {
    image: goldenStateImage,
    price: 900,
    width: 195,
  },
];

export const RebatesSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); // анимация сработает только один раз

  return (
    <>
      <div className="mb-6 flex items-center justify-start">
        <Label label="Rebates" />
      </div>
      <motion.div
        ref={ref}
        initial={{ opacity: 0 }} // Начальные параметры анимации
        animate={isInView ? { opacity: 1 } : {}} // Анимация при появлении
        transition={{ duration: 0.5 }} // Длительность анимации
        className="mb-12 flex w-full items-center justify-between"
      >
        <h2 className="mb-8 w-full text-4xl font-bold">
          <p className="text-center">
            <span className="text-textOrange font-semibold">
              Take Advantage
            </span>{' '}
            <span className="textBlack font-semibold">of</span>
          </p>
          <p className="text-center">
            <span className="text-textBlack font-semibold">ALL available</span>{' '}
            <span className="text-textOrange font-semibold">REBATES</span>
            <span className="text-textBlack font-semibold">
              {' '}
              in California.
            </span>{' '}
          </p>
        </h2>
      </motion.div>
      <ul className={style.list}>
        {items.map((item, idx) => (
          <motion.li
            key={idx}
            className={style.listItem}
            initial={{ opacity: 0, y: 20 }} // Начальные параметры анимации для элементов списка
            animate={isInView ? { opacity: 1, y: 0 } : {}} // Анимация при появлении
            transition={{ duration: 0.5 }} // Длительность анимации
          >
            <RebatesItem
              price={item.price}
              image={item.image}
              width={item.width}
            />
          </motion.li>
        ))}
      </ul>
    </>
  );
};
