import React from 'react';
import style from './AwardsSection.module.scss';
import googleImg from '@/public/images/awards/google.webp';
import thumbtackImg from '@/public/images/awards/thumbtack.webp';
import yelpImg from '@/public/images/awards/yelp.webp';
import angiImg from '@/public/images/awards/angi.webp';
import Image from 'next/image';

export const AwardsSection = () => {
  return (
    <div className={style.container}>
      <div>
        <Image style={{ maxWidth: '180px' }} src={googleImg} alt="google" />
      </div>
      <div>
        <Image
          style={{ maxWidth: '130px' }}
          src={thumbtackImg}
          alt="thumbtackImg"
        />
      </div>
      <div>
        <Image style={{ maxWidth: '224px' }} src={yelpImg} alt="yelpImg" />
      </div>
      <div>
        <Image style={{ maxWidth: '160px' }} src={angiImg} alt="angiImg" />
      </div>
    </div>
  );
};
