import React from 'react';
import style from './BigBlogCard.module.scss';
import Image from 'next/image';
import { Button } from '@/components/ui/button';
import { Icons } from '@/components/icons';

interface IProps {
  image: string;
  title: string;
  date: string;
  time: string;
}

export const BigBlogCard = ({ item }: { item: IProps }) => {
  return (
    <article className={style.article}>
      <Image src={item.image} alt={item.title} />
      <div className={style.overlay}>
        <div className={style.overlayInner}>
          <div className={style.contentWrapper}>
            <p className={style.heading}>
              <span>{item.date}</span>
              <span>
                <Icons.dotIcon />
              </span>
              <span>{item.time}</span>
            </p>
            <div
              style={{
                marginBottom: '20px',
                border: '1px solid #4F4F4F',
                opacity: '20%',
              }}
            ></div>
            <p className="text-2xl text-black font-semibold text-left mb-5">
              {item.title}
            </p>
            <Button className="bg-black text-white py-2 px-5 rounded-full text-lg hover:opacity-75">
              Read More
            </Button>
          </div>{' '}
        </div>
      </div>
    </article>
  );
};
